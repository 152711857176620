<template>
  <div
    class="item"
    :class="[index == item.mainDishList.length - 1 && 'no-border']"
  >
    <div class="imageBox" @click.stop="preview(food.image)" v-if="food.image">
      <img :src="food.image" alt="" />
    </div>
    <div style="flex: 1">
      <div class="name">
        <div class="word-break">
          {{ food.name }}
        </div>
        <div class="operation">
          <svg-icon
            iconClass="zxj"
            className="zxj"
            @click.stop="
              $emit('uploadImage', {
                foodId: food.id,
                cateIndex: cateIndex,
                mainIndex: index,
              })
            "
          ></svg-icon>
          <svg-icon
            iconClass="scan"
            className="scan"
            @click.stop="
              $emit('showScanStaff', {
                foodId: food.id,
                foodName: food.name,
                foodImage: food.image,
                categoryId: item.id,
              })
            "
          ></svg-icon>
        </div>
      </div>

      <div class="upcCode">
        <div class="price">${{ food.fullPrice }}</div>
        <div class="right">
          <div v-if="food.upc">
            <div class="omit">
              &nbsp;UPC:&nbsp;&nbsp;&nbsp;{{ food.upc || "null" }}
            </div>
            <div>
              Time:&nbsp;&nbsp;
              {{
                food.upcUpdateTime ? dealWithDate(food.upcUpdateTime) : "null"
              }}
            </div>
          </div>
          <div
            class="edit"
            @click.stop="
              $emit('showScanStaff', {
                foodId: food.id,
                foodName: food.name,
                foodImage: food.image,
                upc: food.upc,
                categoryId: item.id,
                type: 'edit',
              })
            "
          >
            Edit
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { ImagePreview } from "vant";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    food: {
      type: Object,
      default: () => {},
    },
    addFoodBtn: {
      type: Boolean,
      default: true,
    },
    index: Number,
    cateIndex: Number,
    enableUpc: {
      type: Boolean,
      default: true,
    },
    opt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    dealWithDate(date) {
      let localDate = moment(date).tz(localStorage.getItem("timeZone"));
      return localDate.format("HH:mm MM/DD/YYYY");
    },
    preview(image) {
      ImagePreview({ images: [image], closeable: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  padding: 10pt 0 10pt 0 !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.imageBox {
  width: 50pt;
  height: 50pt;
  overflow: hidden;
  margin-right: 10px;
  flex-shrink: 0;
  img {
    width: 50pt;
    height: 50pt;
  }
}
.no-border {
  border: 0pt !important;
}

.oos {
  background-color: #e8e8e8;
}

.name {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .word-break {
    font-size: 11pt;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #2b2e36;
    display: flex;
    align-items: center;
    width: 80%;
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    /deep/.zxj {
      fill: $primary-color !important;
      font-size: 14pt;
    }
    /deep/.scan {
      fill: $primary-color !important;
      font-size: 16pt;
      font-weight: bold;
      margin-left: 6pt;
    }
  }
}

.upcCode {
  font-size: 10pt;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #2b2e36;
  // display: flex;
  // align-items: flex-start;
  // justify-content: space-between;
  margin-top: 6pt;

  .right {
    // width: 150pt;
    height: 30pt;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    // padding-right: 30pt;
    position: relative;
    .edit {
      position: absolute;
      right: 0;
      bottom: 0;
      text-decoration: underline;
      font-style: italic;
      font-size: 11pt;
      color: $primary-color;
    }
  }
}

.omit {
  width: 150pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
