<template>
  <div class="camera">
    <div class="reader-box">
      <div class="reader" id="reader"></div>
    </div>

    <!-- <input
      type="range"
      @change="rangeChange"
      v-model="range"
      min="0"
      max="10"
    />

    <p>The following media constraints are supported by your browser:</p>

    <ul id="constraintList" style="font-size: 10pt"></ul> -->

    <div class="loading" v-show="scanLoading">
      <van-loading size="24px" vertical>init camera...</van-loading>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import { Toast } from "vant";
export default {
  data() {
    return {
      scanLoading: true,
      html5Qrcode: null,
      range: 0,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   const constraintList = document.querySelector("#constraintList");
    //   const supportedConstraints =
    //     navigator.mediaDevices.getSupportedConstraints();
    //   for (const constraint of Object.keys(supportedConstraints)) {
    //     const elem = document.createElement("li");
    //     elem.innerHTML = `<code>${constraint}</code>`;
    //     constraintList.appendChild(elem);
    //   }
    // }, 3000);
  },
  methods: {
    rangeChange(e) {
      console.log(e);
      this.html5Qrcode.applyVideoConstraints({
        advanced: [{ aspectRatio: Number(e.target.value) }],
      });
    },
    initCamera() {
      this.scanLoading = true;
      this.$nextTick(() => {
        Html5Qrcode.getCameras()
          .then((devices) => {
            console.log("devices===============", devices);
            if (devices && devices.length) {
              this.html5Qrcode = new Html5Qrcode("reader");
              // this.scanLoading = false;
              this.start();
            }
          })
          .catch((e) => {
            Toast("No camera permission");
          });
      });
    },
    start() {
      this.html5Qrcode
        .start(
          { facingMode: "environment" },
          {
            fps: 10,
            qrbox: { width: 300, height: 160 },
            halfSample: true, //缩小二维码提高识别精度吧
            rememberLastUsedCamera: true,
            colorLight: "#ffffff", //这个应该是提高非黑即白部分的亮度  提高识别度
            autofocus: true, //自动对焦  但使用的时候没有体会到
            defaultZoomValueIfSupported: 2,
            willReadFrequently: true,
            videoConstraints: {
              aspectRatio: 2, // <-- this would be honoured
              width: { min: 640, ideal: 1280, max: 1920 },
              height: { min: 480, ideal: 720, max: 1080 },
              facingMode: { ideal: "environment" },
              focusMode: { ideal: "continuous" },
              advanced: [{ zoom: 2.0 }],
            },
          },
          (decodedText, decodedResult) => {
            // Toast(
            //   JSON.stringify(decodedText) +
            //     "==================" +
            //     JSON.stringify(new Date())
            // );

            if (decodedText) {
              this.stop();
            }
            this.$emit("codeResult", decodedText);
          }
        )
        .catch((err) => {
          console.log(`Unable to start scanning, error: ${err}`);
        });
    },
    stop() {
      this.html5Qrcode &&
        this.html5Qrcode
          .stop()
          .then((ignore) => {
            console.log("QR Code scanning stopped.");
          })
          .catch((err) => {
            console.log("Unable to stop scanning.");
          });
    },
  },
  beforeDestroy() {
    this.stop();
  },
};
</script>

<style lang="scss" scoped>
/deep/video {
  height: 70vh !important;
  object-fit: cover;
}
.camera {
  position: relative;
}
.reader-box {
  width: 100vw;
  height: 70vh;
  position: relative;
  z-index: 99;
}
.loading {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100pt;
  left: 50%;
  transform: translateX(-50%);
}
</style>
