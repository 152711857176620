<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  computed: {},
};
</script>

<style lang="less">
// :root {
//   //--primary-color: #FF6633;
//   --primary-color: #000;
// }
.content {
  padding: 0 6pt !important;
}

@media screen and (min-width: 700pt) {
  #app {
    width: 400pt !important;
  }

  .foodModel {
    width: 400pt !important;
    left: auto !important;
  }

  .van-overlay {
    width: 400pt !important;
    left: calc(50% - 200pt) !important;
  }

  .van-popup--bottom {
    width: 400pt !important;
    left: calc(50% - 200pt) !important;
  }

  .van-sticky--fixed {
    width: 400pt !important;
    left: calc(50% - 200pt) !important;
  }

  .van-image-preview {
    width: 400pt !important;
    left: calc(50% - 200pt) !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  background: #f5f4f4;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.toast {
  word-break: normal;
}
</style>
