<template>
  <van-popup v-model="showScan" lock-scroll position="bottom" @closed="_closed">
    <div class="scanCode" v-if="showCamera">
      <Camera ref="Camera" @codeResult="html5QrcodeSuccessCallback" />

      <div class="stopBtn" @click="stopScan">Stop Scanning</div>
    </div>

    <van-popup
      v-model="showDetail"
      position="bottom"
      round
      closeable
      safe-area-inset-bottom
      @click-close-icon="_closedIconDetail"
      @closed="_closedDetail"
    >
      <div class="detailCon">
        <div class="productTitle">UPC Code Detected</div>
        <div class="form">
          <div class="input-item">
            <span>UPC code</span>
            <input type="text" placeholder="input code" v-model="form.upc" />
          </div>
          <div
            class="input-item"
            style="margin-top: 10pt"
            v-if="type === 'add'"
          >
            <span>Menu Item</span>
            <input
              readonly
              :value="form.menuName"
              type="text"
              placeholder="Please select menu item"
              class="selectIcon"
              @click="selectMenu"
            />
          </div>
          <div class="input-item" style="margin-top: 10pt">
            <span>Add Picture</span>
            <div class="picture">
              <div class="uploadWay">
                <van-uploader
                  :after-read="afterRead"
                  preview-size="40"
                  :max-count="1"
                >
                  <div class="haveUploadImg">
                    <img
                      :src="form.image"
                      alt=""
                      @click.stop=""
                      v-if="form.image"
                    />
                    <svg-icon v-else iconClass="zxj" className="zxj"></svg-icon>
                  </div>
                </van-uploader>
              </div>
            </div>
          </div>
        </div>

        <div class="operationBtn">
          <div class="addBtn" @click="scanAgain">Scan</div>
          <div class="cancelBtn" @click="dataFormSubmit">Save</div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="slectMenu" position="bottom" round>
      <div class="selectMenu">
        <van-tree-select
          :items="categoryList"
          :active-id.sync="form.id"
          :main-active-index.sync="activeIndex"
          @click-item="confirmMenu"
        />
      </div>
    </van-popup>

    <van-popup class="upsellModel" v-model="showError" round>
      <div class="content">Code scanning timeout</div>
      <div class="btn-group">
        <van-button
          class="btn-skip"
          style="height: 30px"
          plain
          @click="scanAgain"
          >Try again</van-button
        >
        <van-button class="btn-skip" style="height: 30px" plain @click="_closed"
          >Go back</van-button
        >
      </div>
    </van-popup>
  </van-popup>
</template>

<script>
import Camera from "@/components/camera.vue";
import request from "@/utils/request";
import { Toast, ImagePreview } from "vant";
export default {
  data() {
    return {
      type: "add",
      showScan: false,
      showCamera: true,
      showDetail: false,
      showError: false,
      imageList: [],

      categoryList: [],
      slectMenu: false,
      activeIndex: 0,

      initData: {},

      form: {
        id: undefined,
        menuName: undefined,
        upc: undefined,
        image: undefined,
      },

      overtimeTimeout: null,
    };
  },
  components: { Camera },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.showCamera = false;
        clearTimeout(this.overtimeTimeout);
        this.overtimeTimeout = null;
      }
    },
    showError(newVal) {
      if (newVal) {
        clearTimeout(this.overtimeTimeout);
        this.overtimeTimeout = null;
      }
    },
    showCamera: {
      handler(newVal) {
        if (newVal) {
          clearTimeout(this.overtimeTimeout);
          this.overtimeTimeout = null;
          this.overtimeTimeout = setTimeout(() => {
            // 扫描超时
            this.showCamera = false;
            this.showError = true;
          }, 30000); // 超时倒计时
        }
      },
      immediate: true,
    },
  },
  methods: {
    dataFormSubmit() {
      const { id, upc, image } = this.form;
      if (!upc) {
        Toast("UPC code can not be empty");
        return;
      }
      if (!id) {
        Toast("Menu Item code can not be empty");
        return;
      }

      request
        .post("/adminApi/foodOrder/saveMainDishUpc", {
          id,
          upc,
          hotelId: localStorage.getItem("hotelId"),
          image,
          user: localStorage.getItem("staffUserName"),
        })
        .then((res) => {
          if (res.status === "0") {
            this.$emit("successCallback", {
              upcUpdateTime: new Date(),
              activeIndex: this.activeIndex,
              mainIndex: this.categoryList[this.activeIndex].children.findIndex(
                (item) => item.id == this.form.id
              ),
              upc: this.form.upc,
              image: this.form.image,
            });
            Toast("SUCCESS");
            this.showDetail = false;
          } else {
            Toast("Error" + res.msg);
          }
        });
    },
    selectMenu() {
      this.slectMenu = true;
    },
    confirmMenu(e) {
      this.form.id = e.id;
      this.form.menuName = e.text;
      this.slectMenu = false;
    },
    init(params) {
      const {
        categoryList,
        categoryId,
        foodId,
        foodName,
        foodImage,
        type,
        upc,
      } = params;
      this.type = type || "add";
      this.categoryList = categoryList;
      // this.form.id = foodId;
      // this.form.menuName = foodName;
      // this.form.upc = upc;
      // if (categoryId) {
      //   this.activeIndex = this.categoryList.findIndex(
      //     (item) => item.id === categoryId
      //   );
      // }
      // if (foodImage) {
      //   this.form.image = foodImage;
      // }
      this.initData = {
        // 没有直接赋值给form 扫描得到upc 会先检测是UPC是否已经绑定   未绑定 ==> 显示点击进入的类目 已绑定 显示绑定中的类目
        categoryId,
        foodId,
        foodName,
        foodImage,
      };
      console.log("this.initData", this.initData);

      this.showScan = true;
      if (params.type === "edit") {
        this.form.upc = upc;
        this.initDetailData({});
        return;
      }
      this.$nextTick(() => {
        this.$refs.Camera.initCamera();
      });
    },
    html5QrcodeSuccessCallback(decodedText) {
      // 初始化
      this.form.upc = decodedText;
      let mainItem = {};
      for (var i = 0; i < this.categoryList.length; i++) {
        let item = this.categoryList[i].children.find(
          (childItem) => childItem.upc == decodedText
        );
        if (item && item.id) {
          mainItem = {
            ...item,
            i,
          };
          break;
        }
        // if (item && item.id) {
        //   const { text, id, image } = item;
        //   this.activeIndex = i;
        //   this.form.menuName = text;
        //   this.form.image = image;
        //   this.form.id = id;
        //   break;
        // }
      }

      this.initDetailData(mainItem);
    },

    initDetailData(data) {
      if (!data.id) {
        const { categoryId, foodId, foodName, foodImage } = this.initData;
        this.form.id = foodId;
        this.form.menuName = foodName;
        this.form.image = foodImage;
        if (categoryId) {
          this.activeIndex = this.categoryList.findIndex(
            (item) => item.id === categoryId
          );
        }
      } else {
        const { text, id, image, i } = data;
        this.activeIndex = i;
        this.form.menuName = text;
        this.form.image = image;
        this.form.id = id;
      }

      this.showDetail = true;
    },

    stopScan() {
      this.showScan = false;
    },

    scanAgain() {
      // 重新扫码
      this.showError = false;
      this.showDetail = false;
      this.showCamera = true;
      this.$nextTick(() => {
        this.$refs.Camera.initCamera();
      });
    },

    _closed() {
      this.$emit("closed");
    },
    _closedIconDetail() {
      if (this.type === "edit") {
        this.$emit("closed");
      } else {
        this.scanAgain();
      }
    },
    _closedDetail() {
      if (!this.showCamera) {
        this.$emit("closed");
      }
    },

    preview(image) {
      ImagePreview({ images: [image], closeable: true });
    },

    afterRead(file) {
      console.log(file);
      const formData = new FormData();
      formData.append("file", file.file);
      request
        .post("/adminApi/file/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === "0") {
            this.form.image = res.data;
          }
        })
        .catch((err) => {});
    },
    beforeDelete() {
      this.form.image = "";
    },
  },

  beforeDestroy() {
    clearTimeout(this.overtimeTimeout);
    this.overtimeTimeout = null;
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-sidebar-item--select::before {
  background-color: $primary-color;
}
/deep/.van-tree-select__item--active {
  color: $primary-color;

  .van-tree-select__selected {
    color: $primary-color;
  }
}
/deep/.van-sidebar-item__text {
  color: $primary-color;
}
.scanCode {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
}

.stopBtn {
  width: 100pt;
  height: 30pt;
  border: 2px solid #eee;
  color: #333;
  text-align: center;
  line-height: 30pt;
  margin: 30pt auto 0;
  border-radius: 6pt;
  font-size: 13pt;
  font-family: PingFangSC-Regular, PingFang SC;
}
.detailCon {
  padding: 20pt 0;
  margin: 0 6pt;

  .productTitle {
    font-size: 14pt;
    font-weight: bold;
    color: #333;
    padding-bottom: 10pt;
    border-bottom: 2px solid #eee;
    font-family: PingFangSC-Regular, PingFang SC;
    text-align: left;
    padding-left: 10pt;
  }
}

.form {
  margin: 10pt 24pt 30pt 16pt;
}

.input-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    color: #666;
    font-size: 10pt;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  input {
    width: 100%;
    border: 1px solid #666;
    font-size: 12pt;
    margin-top: 4px;
    padding-left: 20px;
    line-height: 24pt;
    color: #333;
    font-weight: bold;
    position: relative;
  }
  input::placeholder {
    font-weight: normal;
  }

  .selectIcon {
    background-image: url("../assets/img/arrow-bottom.png");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;
  }
}

.picture {
  display: flex;
  align-items: center;
  margin-top: 4pt;

  .uploadWay {
  }

  .haveUploadImg {
    // margin-left: 30pt;
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f8fa;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.upsellModel {
  padding: 10pt 10pt 20pt 10pt !important;
  width: 260pt;
  background: #f9f9f9;
  font-size: 12pt;

  .title {
    font-weight: 600;
    font-size: 12pt;
    margin-bottom: 10pt;
  }

  /deep/.van-icon {
    color: $primary-color;
  }

  .content {
    height: 30pt;
    .item {
      line-height: 16pt;
      padding: 4pt 0;
      font-size: 11pt;

      span {
        text-decoration: underline;
      }
    }
  }

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    margin-top: 16pt;

    .btn-skip {
      border-color: $primary-color;
      color: $primary-color;
      width: 100%;
    }
  }
}

.operationBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10pt;

  div {
    width: 40%;
    height: 30pt;
    background-color: $primary-color;
    color: #fff;
    font-size: 16pt;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 30pt;
    border-radius: 2pt;
  }
  div:nth-child(2) {
    margin-left: 30pt;
  }
}

.selectMenu {
  padding-top: 10pt;
}

.upsellModel {
  padding: 10pt;
  width: 260pt;
  background: #f9f9f9;
  font-size: 12pt;

  .title {
    font-weight: 600;
    font-size: 12pt;
    margin-bottom: 10pt;
  }

  /deep/.van-icon {
    color: $primary-color;
  }

  .content {
    height: 30pt;
    .item {
      line-height: 16pt;
      padding: 4pt 0;
      font-size: 11pt;

      span {
        text-decoration: underline;
      }
    }
  }

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    margin-top: 26pt;

    .btn-skip {
      border-color: $primary-color;
      color: $primary-color;
      width: 100%;
    }
  }
}
</style>
