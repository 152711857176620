const food = {
  state: {
    foods: [],
    order: [],
    allPrice: 0,
    allNum: 0,
    mealPeriodOpenTime: [],
    discount: null
  },
  mutations: {
    ADD_FOOD: (state, data) => {
      state.foods.push(data);
      state.allPrice = 0;
      state.allNum = 0;
      for (let i = 0; i < state.foods.length; i++) {
        const element = state.foods[i];
        state.allPrice += element.allPrice;
        state.allNum += element.num;
      }
    },
    REMOVE_FOOD: (state, data) => {
      state.foods = state.foods.filter((item) => {
        return item.id != data;
      });
      state.allPrice = 0;
      state.allNum = 0;
      for (let i = 0; i < state.foods.length; i++) {
        const element = state.foods[i];
        state.allPrice += element.allPrice;
        state.allNum += element.num;
      }
    },
    EDIT_FOOD: (state, data) => {
      for (let i = 0; i < state.foods.length; i++) {
        if (state.foods[i].id == data.id) {
          state.foods[i] = data;
        }
      }
      state.allPrice = 0;
      state.allNum = 0;
      for (let i = 0; i < state.foods.length; i++) {
        const element = state.foods[i];
        state.allPrice += element.allPrice;
        state.allNum += element.num;
      }
    },
    ADD_ORDER: (state, data) => {
      state.order = data;
      state.foods = [];
      state.allPrice = 0;
      state.allNum = 0;
    },
    UPDETE_MEAL_PERIOD_OPEN_TIME: (state, data) => {
      let target = state.mealPeriodOpenTime.find((item) => {
        return item.mealPeriodId == data.mealPeriodId;
      });
      if (!target) {
        state.mealPeriodOpenTime.push(data);
      } else {
        for (let i = 0; i < state.mealPeriodOpenTime.length; i++) {
          if (state.mealPeriodOpenTime[i].mealPeriodId == data.mealPeriodId) {
            state.mealPeriodOpenTime[i] = data;
            break;
          }
        }
      }
    },
    EDIT_COLOR: (state, data) => {
      state.primaryColor = data;
      //修改全局变量
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("--primaryColor", `${state.primaryColor}`);
    },
    ADD_DISCOUNT: (state, data) => {
      state.discount = data
    },
    REMOVE_DISCOUNT: (state) => {
      state.discount = null
    }
  },
  actions: {
    addFood: ({ commit }, data) => {
      commit("ADD_FOOD", data);
    },
    removeFood: ({ commit }, data) => {
      commit("REMOVE_FOOD", data);
    },
    editFood: ({ commit }, data) => {
      commit("EDIT_FOOD", data);
    },
    addOrder: ({ commit }, data) => {
      commit("ADD_ORDER", data);
    },
    updateMealPeriodOpenTime: ({ commit }, data) => {
      commit("UPDETE_MEAL_PERIOD_OPEN_TIME", data);
    },
    editColor: ({ commit }, data) => {
      commit("EDIT_COLOR", data);
    },
    addDiscount: ({ commit }, data) => {
      commit("ADD_DISCOUNT", data);
    },
    removeDiscount: ({ commit }) => {
      commit("REMOVE_DISCOUNT");
    },
  },
};

export default food;
