<template>
  <van-popup v-model="showScan" lock-scroll position="bottom" @closed="_closed">
    <div class="scanCode" v-if="showCamera">
      <Camera ref="Camera" @codeResult="getDetail" />

      <div class="stopBtn" @click="stopScan">Stop Scanning</div>
    </div>

    <van-popup
      v-model="showDetail"
      position="bottom"
      round
      closeable
      safe-area-inset-bottom
      @closed="_closedDetail"
    >
      <div class="detailCon">
        <div class="productTitle">{{ foodInfo.name }}</div>
        <div class="productIntroduce">
          <div class="productIntroduce-left">
            <p class="introduce">
              {{ foodInfo.description }}
            </p>
            <div class="price">
              <p>Price</p>
              <p>$ {{ foodInfo.fullPrice }}</p>
            </div>
            <div class="price">
              <p>Quantity</p>
              <van-stepper v-model="num" integer :disable-input="true" />
            </div>
          </div>
          <div class="product-image" @click="preview(foodInfo.image)">
            <img :src="foodInfo.image" alt="" />
          </div>
        </div>
        <div class="addBtn" @click="addTrolley">ADD</div>
        <div class="cancelBtn" @click="cancelBtn">CANCEL</div>
      </div>
    </van-popup>

    <van-popup class="upsellModel" v-model="showError" round>
      <div class="content">{{ errorInfo }}</div>
      <div class="btn-group">
        <van-button
          class="btn-skip"
          style="height: 30px"
          plain
          @click="scanAgain"
          >Try again</van-button
        >
        <van-button class="btn-skip" style="height: 30px" plain @click="_closed"
          >Go back</van-button
        >
      </div>
    </van-popup>
  </van-popup>
</template>

<script>
import Camera from "@/components/camera.vue";
import { Toast, ImagePreview } from "vant";
export default {
  data() {
    return {
      showScan: false, // 最外围弹框
      showCamera: true, // 扫码页面
      showDetail: false, // 扫描二维码查询结果弹框
      showError: false, // 超时/未匹配提示
      errorInfo: "Matching barcode not found.",

      html5Qrcode: null,
      overtimeTimeout: null,

      num: 1, // 购买数量
      categoryList: [],
      foodInfo: {},
    };
  },
  components: { Camera },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.showCamera = false;
        clearTimeout(this.overtimeTimeout);
        this.overtimeTimeout = null;
      }
    },
    showError(newVal) {
      if (newVal) {
        this.showCamera = false;
        this.showDetail = false;
        clearTimeout(this.overtimeTimeout);
        this.overtimeTimeout = null;
      }
    },
    showCamera: {
      handler(newVal) {
        if (newVal) {
          clearTimeout(this.overtimeTimeout);
          this.overtimeTimeout = null;
          this.overtimeTimeout = setTimeout(() => {
            // 扫描超时
            this.showCamera = false;
            this.showError = true;
          }, 30000); // 超时倒计时
        }
      },
      immediate: true,
    },
  },
  methods: {
    addTrolley() {
      this.showDetail = false;
      if (this.foodInfo.oosStatus) {
        Toast("Sold Out");
        return;
      }
      this.$emit(
        "confirmFood",
        Object.assign({}, this.foodInfo, { num: this.num })
      );
      this.showScan = false;
    },
    init(categoryList) {
      this.categoryList = categoryList;
      this.showScan = true;
      this.$nextTick(() => {
        this.$refs.Camera.initCamera();
      });
    },
    getDetail(decodedText) {
      this.showDetail = true;
      this.foodInfo = {};
      let foodInfo = {};
      for (var i = 0; i < this.categoryList.length; i++) {
        let item = this.categoryList[i].mainDishList.find(
          (mainItem) => mainItem.upc == decodedText
        );
        if (item && item.id) {
          foodInfo = {
            ...item,
            categoryId: this.categoryList[i].id,
          };
          break;
        }
      }
      if (foodInfo && foodInfo.id) {
        this.foodInfo = foodInfo;
        this.num = 1;
        this.showDetail = true;
      } else {
        this.errorInfo = "Matching barcode not found.";
        this.showError = true;
      }
    },

    stopScan() {
      this.showScan = false;
    },

    cancelBtn() {
      this.showDetail = false;
      this.showScan = false;
    },

    scanAgain() {
      // 重新扫码
      this.showError = false;
      this.showDetail = false;
      this.showCamera = true;
      this.$nextTick(() => {
        this.$refs.Camera.initCamera();
      });
    },

    _closedDetail() {
      if (!this.showCamera) {
        this.$emit("closed");
      }
    },

    _closed() {
      this.$emit("closed");
    },

    preview(image) {
      ImagePreview({ images: [image], closeable: true });
    },
  },

  beforeDestroy() {
    clearTimeout(this.overtimeTimeout);
    this.overtimeTimeout = null;
  },
};
</script>

<style lang="scss" scoped>
/deep/video {
  height: 70vh !important;
  object-fit: cover;
}
.scanCode {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
}
.reader-box {
  width: 100vw;
  height: 70vh;
  // background-color: rgba(0, 0, 0, 0.3);
}
.stopBtn {
  width: 100pt;
  height: 30pt;
  border: 2px solid #eee;
  color: #333;
  text-align: center;
  line-height: 30pt;
  margin: 30pt auto 0;
  border-radius: 6pt;
  font-size: 13pt;
  font-family: PingFangSC-Regular, PingFang SC;
}
.detailCon {
  padding: 20pt 0;
  margin: 0 6pt;

  .productTitle {
    text-align: center;
    font-size: 14pt;
    font-weight: bold;
    color: #333;
    padding-bottom: 10pt;
    border-bottom: 2px solid #eee;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .productIntroduce {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10pt 0 20pt 0;
    border-bottom: 2px solid #eee;
  }

  .productIntroduce-left {
    flex: 1;

    .introduce {
      font-size: 10pt;
      color: #333;
      font-family: PingFangSC-Regular, PingFang SC;
      text-align: left;
    }

    .price {
      display: flex;
      align-items: center;
      margin-top: 14pt;

      p:first-of-type {
        font-size: 14pt;
        color: #333;
        font-family: PingFangSC-Regular, PingFang SC;
        width: 80pt;
        margin: 0;
        text-align: left;
        font-weight: bold;
      }

      p:nth-child(2) {
        font-weight: bold;
        margin-left: 6pt;
        font-size: 14pt;
        color: #333;
        font-family: PingFangSC-Regular, PingFang SC;
        margin: 0;
      }
    }
  }
}

.product-image {
  width: 80pt;
  height: 120pt;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 10pt;

  img {
    width: 60pt;
    height: auto;
  }
}

.addBtn {
  margin: 10pt 10pt 0 10pt;
  height: 30pt;
  font-size: 12pt;
  color: #fff;
  border-radius: 6px;
  text-align: cneter;
  line-height: 30pt;
  background: $primary-color;
}
.cancelBtn {
  font-size: 12pt;
  text-decoration: underline;
  height: 30pt;
  line-height: 30pt;
  margin-top: 5pt;
  color: $primary-color;
}

.upsellModel {
  padding: 10pt 10pt 20pt 10pt !important;
  width: 260pt;
  background: #f9f9f9;
  font-size: 12pt;

  .title {
    font-weight: 600;
    font-size: 12pt;
    margin-bottom: 10pt;
  }

  /deep/.van-icon {
    color: $primary-color;
  }

  .content {
    height: 30pt;
    .item {
      line-height: 16pt;
      padding: 4pt 0;
      font-size: 11pt;

      span {
        text-decoration: underline;
      }
    }
  }

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    margin-top: 16pt;

    .btn-skip {
      border-color: $primary-color;
      color: $primary-color;
      width: 100%;
    }
  }
}

.loading {
  position: fixed;
  top: 100pt;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
