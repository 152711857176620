<template>
  <div class="home" ref="home">
    <van-nav-bar :left-arrow="false" @click-left="onClickLeft">
      <!-- <template #left>
        <img
          :src="brandUrl"
          alt=""
          style="height: 24pt; width: auto; background-color: #fff"
        />
      </template> -->
      <template #title>
        <div style="display: flex; align-items: center">
          <img
            :src="brandUrl"
            alt=""
            style="height: 24pt; width: auto; background-color: #fff"
          />
          <div class="hotelname word-break" style="white-space: pre-wrap">
            {{ hotelName }}
          </div>
        </div>
      </template>

      <template #right>
        <van-badge v-if="order.length > 0" :content="order.length">
          <img
            src="@/assets/img/全部订单.png"
            @click="goMyOrder"
            alt=""
            style="height: 19.5pt"
          />
        </van-badge>
        <img
          v-else
          src="@/assets/img/全部订单.png"
          @click="goMyOrder"
          alt=""
          style="height: 19.5pt"
        />
      </template>
    </van-nav-bar>

    <div class="section-area">
      <van-icon
        class="arrow-left"
        v-if="mealPeriodList.length > 3"
        size="15pt"
        name="arrow-left"
      />
      <div ref="section" class="section">
        <div
          :id="mealPeriod.id"
          :ref="mealPeriod.id"
          class="item"
          v-for="(mealPeriod, index) in mealPeriodList"
          :key="index"
          @click="chooseType(mealPeriod)"
        >
          <div
            v-if="!loading"
            class="top-text word-break"
            style="background-color: lightgrey"
            :class="{ activeType: active === mealPeriod.id }"
          >
            {{ mealPeriod.mealPeriod }}
          </div>
        </div>
      </div>
      <van-icon
        class="arrow-right"
        v-if="mealPeriodList.length > 3"
        size="15pt"
        name="arrow"
      />
    </div>
    <div
      v-show="tips"
      style="
        width: 100%;
        height: 18pt;
        border-top: 2pt solid #dfdfdf;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img
        v-show="timeOpen"
        style="height: 12pt; margin-right: 5pt"
        src="@/assets/img/时间.png"
      />
      <img
        v-show="!timeOpen"
        style="height: 12pt; margin-right: 5pt"
        src="@/assets/img/时间2.png"
      />
      <span
        class="word-break"
        style="
          font-size: 10pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 10pt;
          color: #636464;
        "
        >{{ tips }}</span
      >
    </div>

    <div class="content" ref="categoryContent">
      <div
        style="
          font-size: 10pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #636464;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        "
        ref="tips"
        v-if="!loading"
      >
        <!--        <span class="word-break" v-show="timeOpen && nearEndTipTime"-->
        <!--        >Ordering ends at {{ nearEndTipTime }}.</span-->
        <!--        >-->
        <span class="word-break" v-show="mealPeriodList.length == 0"
          >We are not accepting immediate orders right now.</span
        >
        <span
          class="word-break"
          v-show="
            !timeOpen &&
            isAllowAdvanced &&
            mealPeriodList.length > 0 &&
            tips != 'Not available in the next two weeks.'
          "
          >We are not accepting immediate orders right now, but you can still
          place early orders.</span
        >
        <span
          class="word-break"
          v-show="
            nearEndTipTime != '' &&
            addFoodBtn === true &&
            !nowMealPeriodAdvanced
          "
          style="text-indent: 25px"
          >Ordering ends at {{ nearEndTipTime }}.</span
        >
        <span
          class="word-break"
          v-show="
            nearEndTipTime != '' &&
            addFoodBtn === false &&
            !nowMealPeriodAdvanced
          "
          style="text-indent: 25px"
          >Ordering ended at
          <!--            moment(endTimeTody.valueOf() - 1000 * 60 * 10).tz(localStorage.getItem("timeZone")).format("hh:mm A")-->
          {{ nearEndTipTime }}.</span
        >
      </div>
      <van-collapse
        v-model="activeName"
        accordion
        @change="activeNameChange"
        ref="vanCollapse"
      >
        <van-collapse-item
          v-for="(item, index) in categoryList"
          :key="index"
          :title="item.categoryName"
          :label="item.description"
          :name="item.id"
          class="food-collapse"
          :id="'collapse' + item.id"
          :ref="'collapse' + item.id"
        >
          <div v-if="enableUpc && opt === 'staff'">
            <MainStaffEnableUpc
              v-for="(food, mainIndex) in item.mainDishList"
              :item="item"
              :food="food"
              :key="mainIndex"
              :index="mainIndex"
              :cateIndex="index"
              :enableUpc="enableUpc"
              :opt="opt"
              :addFoodBtn="addFoodBtn"
              @showScanStaff="(params) => showScanStaff(params)"
              @uploadImage="staffUploadFoodImage"
            />
          </div>
          <div v-else>
            <MainItem
              v-for="(food, index) in item.mainDishList"
              :item="item"
              :food="food"
              :key="index"
              :index="index"
              :addFoodBtn="addFoodBtn"
              :id="'collapseMainItem' + food.id"
              @showFoodInfo="
                (food) => {
                  showFoodInfo(food);
                }
              "
              @showIconInfo="showIconInfo"
            />
          </div>
          <!-- <div
            v-for="(food, i) in item.mainDishList"
            :key="i"
            :class="{
              'no-border': i == item.mainDishList.length - 1,
              oos: food.oosStatus,
            }"
            class="item"
            @click="showFoodInfo(food)"
            style="display: flex"
          >
            <div
              v-if="food.image"
              @click.stop="preview(food.image)"
              style="margin-right: 10px"
            >
              <img :src="food.image" style="width: 100px; height: 100px" />
            </div>
            <div style="width: 100%">
              <div class="name">
                <span class="word-break">{{ food.name }}</span>
              </div>
              <div v-if="showPrice" class="price" >
                <span class="word-break"> &nbsp;${{ food.fullPrice }} </span>
              </div>
              <div class="name" style="margin: 5px 0">
                <div
                  v-if="
                    food.type &&
                    food.type.split(',').indexOf('GF (Gluten Free)') != -1
                  "
                  style="position: relative"
                  @click.stop="showIconInfo()"
                >
                  <img class="icon" src="../assets/img/1.png" />
                  <img class="tip" src="../assets/img/提示.png" />
                </div>
                <div
                  style="position: relative"
                  v-if="
                    food.type &&
                    food.type.split(',').indexOf('DF (Dairy Free)') != -1
                  "
                  @click.stop="showIconInfo()"
                >
                  <img class="icon" src="../assets/img/2.png" />
                  <img class="tip" src="../assets/img/提示.png" />
                </div>
                <div
                  style="position: relative"
                  v-if="
                    food.type &&
                    food.type.split(',').indexOf('VG (Vegan)') != -1
                  "
                  @click.stop="showIconInfo()"
                >
                  <img class="icon" src="../assets/img/3.png" />
                  <img class="tip" src="../assets/img/提示.png" />
                </div>
                <div
                  style="position: relative"
                  v-if="
                    food.type &&
                    food.type.split(',').indexOf('V (Vegetarian)') != -1
                  "
                  @click.stop="showIconInfo()"
                >
                  <img class="icon" src="../assets/img/4.png" />
                  <img class="tip" src="../assets/img/提示.png" />
                </div>
                <div
                  style="position: relative"
                  v-if="
                    food.type &&
                    food.type.split(',').indexOf('NF (Nuts Free)') != -1
                  "
                  @click.stop="showIconInfo()"
                >
                  <img class="icon" src="../assets/img/5.png" />
                  <img class="tip" src="../assets/img/提示.png" />
                </div>
              </div>
              <div class="info">
                <span class="word-break">
                  {{ food.description }}
                </span>
              </div>
              <div class="add" v-if="addFoodBtn">
                <template v-if="!food.oosStatus">
                  <van-badge v-if="food.num > 0" :content="food.num">
                    <van-icon name="add" size="15pt" />
                  </van-badge>
                  <van-icon v-else name="add" size="15pt" />
                </template>
                <div v-else>
                  <span style="font-weight: 550">Sold Out &nbsp;&nbsp;</span>
                </div>
              </div>
            </div>
          </div> -->
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 非扫码商品 -->
    <div class="bottom" v-if="!enableUpc">
      <div style="display: flex; align-items: center">
        <van-badge
          v-if="allNum > 0"
          :content="allNum"
          style="margin-right: 10pt; top: 0pt"
          class="shoppingCart"
        >
          <img src="../assets/img/购物车.png" alt="" @click="placeOrder" />
        </van-badge>
        <img
          v-else
          src="../assets/img/购物车.png"
          style="margin-right: 10pt"
          alt=""
          @click="placeOrder"
        />
        <span v-if="showPrice" class="price">${{ allPrice.toFixed(2) }}</span>
      </div>

      <div class="btn" @click="placeOrder">
        <span>MY ORDER</span>
      </div>
    </div>

    <!-- 扫码商品 -->
    <div v-else style="width: 100%; display: flex; justify-content: center">
      <div class="footer" v-if="opt !== 'staff'">
        <div class="footer-item scanBtn" @click="scanCode">
          <svg-icon iconClass="scan" className="footer-scan"></svg-icon>
          <span>Scan&nbsp;Barcode</span>
        </div>
        <div class="footer-item" @click="placeOrder">
          <van-badge v-if="allNum > 0" :content="allNum">
            <svg-icon
              iconClass="gwc"
              style="width: 18pt; height: 18pt; font-size: 0"
            ></svg-icon>
          </van-badge>
          <svg-icon v-else iconClass="gwc" style="width: 18pt"></svg-icon>
          <span style="margin-left: 10px">${{ allPrice.toFixed(2) }}</span>
        </div>
      </div>

      <div
        class="footer-staff"
        @click="showScanStaff({ type: 'add' })"
        v-if="opt === 'staff'"
      >
        Scan Barcode
      </div>
    </div>

    <van-popup
      class="foodModel"
      v-model="foodInfoShow"
      round
      position="bottom"
      :style="{ height: '75%' }"
    >
      <div style="height: 100%; background-color: #ffffff">
        <div class="title">
          <span class="word-break">{{ selectFood.name }}</span>
          <van-icon @click="close" class="icon" size="15pt" name="cross" />
        </div>
        <div
          style="
            height: calc(100% - 130pt);
            overflow-y: auto;
            background-color: #ffffff;
          "
        >
          <div class="item">
            <div class="item-name">
              <span>Quantity</span>
            </div>
            <van-stepper v-model="selectFood.num" min="0" integer />
          </div>
          <!-- <div
            class="item"
            v-if="selectFood.halfPrice != null && selectFood.halfPrice != 0"
          >
            <div class="item-name">
              <span class="word-break"
                >{{ selectFood.halfName }} Or {{ selectFood.fullName }}</span
              >
            </div>
            <van-radio-group class="radio-group" v-model="selectFood.isFull">
              <van-radio :name="false" icon-size="12pt">
                {{ selectFood.halfName }} &nbsp;&nbsp;
                <span>${{ selectFood.halfPrice }}</span>
              </van-radio>
              <van-radio :name="true" icon-size="12pt">
                {{ selectFood.fullName }} &nbsp;&nbsp;
                <span>${{ selectFood.fullPrice }}</span>
              </van-radio>
            </van-radio-group>
          </div> -->
          <div
            class="item"
            v-for="(item, index) in selectFood.foods"
            :key="index"
          >
            <div class="item-name">
              <span class="word-break"
                >{{ item.title
                }}{{
                  item.maxNum == 1
                    ? " - Choose 1"
                    : " - Choose up to " + item.maxNum
                }}</span
              >
            </div>

            <!-- <van-radio-group
              v-if="item.type == 'Radio'"
              class="radio-group"
              v-model="item.checked"
            >
              <van-radio
                v-for="food in item.optionList"
                :key="food.id"
                :name="food.id"
                icon-size="12pt"
              >
                {{ food.name }} &nbsp;&nbsp;
                <span v-if="food.price && food.price != 0"
                  >+&nbsp;${{ food.price }}</span
                >
              </van-radio>
            </van-radio-group> -->

            <van-checkbox-group
              class="checked-group"
              v-model="item.checked"
              @change="checkedChange(item)"
            >
              <van-checkbox
                v-for="food in item.optionList"
                :key="food.id"
                :shape="
                  item.type == 'Radio' && item.maxNum == 1 ? 'round' : 'square'
                "
                icon-size="12pt"
                :name="food.id"
                @click="checkbox(item, food.id)"
                >{{ food.name }} &nbsp;&nbsp;
                <span v-if="food.price && food.price != 0 && showPrice"
                  >+&nbsp;${{ food.price }}</span
                ></van-checkbox
              >
            </van-checkbox-group>
          </div>
          <div
            class="item"
            v-if="selectFood.drinks && selectFood.drinks.length > 0"
          >
            <div class="item-name">
              <span>Beverage</span>
            </div>
            <div class="select">
              <div
                v-for="(item, index) in selectFood.drinks"
                :key="index"
                :class="item.checked ? 'type-checked' : 'type'"
                @click="choseDrink(item.id)"
              >
                <span>{{ item.title }}</span>
              </div>
            </div>

            <!--            <van-checkbox-group-->
            <!--              class="checked-group"-->
            <!--              v-model="selectFood.drinksChildrenChecked"-->
            <!--              :min="1"-->
            <!--              :max="2"-->
            <!--            >-->
            <!--              <van-checkbox-->
            <!--                v-for="item in selectFood.drinksChildren"-->
            <!--                :key="item.id"-->
            <!--                :name="item.id"-->
            <!--                shape="square"-->
            <!--                icon-size="12pt"-->
            <!--                @click="radioChange('beverage')"-->
            <!--              >-->
            <!--                {{ item.name }}&nbsp;&nbsp;<span-->
            <!--                v-if="item.price && item.price != 0"-->
            <!--              >+&nbsp;${{ item.price }}</span-->
            <!--              >-->
            <!--              </van-checkbox>-->
            <!--            </van-checkbox-group>-->

            <van-radio-group
              class="radio-group"
              v-model="selectFood.drinksChildrenChecked"
            >
              <van-radio
                v-for="item in selectFood.drinksChildren"
                :key="item.id"
                :name="item.id"
                icon-size="12pt"
              >
                {{ item.name }}&nbsp;&nbsp;<span
                  v-if="item.price && item.price != 0 && showPrice"
                  >+&nbsp;${{ item.price }}</span
                >
              </van-radio>
            </van-radio-group>
          </div>
          <div
            class="remark"
            v-if="posConfig.posBridge == 'HVT' || selectFood.messageModifierId"
          >
            <div class="item-name" style="font-size: 12pt">
              <span class="word-break">Special Instructions</span>
              <br />
              <span
                class="word-break"
                style="font-size: 10pt; font-weight: normal"
                >(Please indicate any allergies or dietary restrictions)</span
              >
            </div>
            <van-field
              maxlength="60"
              class="input word-break"
              v-model="selectFood.remark"
              type="input"
            />
          </div>
        </div>

        <div style="padding: 10pt" v-if="this.addFoodBtn">
          <van-button
            class="btn"
            style="color: #fff"
            size="large"
            @click="addShoppingFood"
            >ADD
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-popup class="iconModel" v-model="showIcon" round>
      <div class="title">
        <span class="word-break">Good To Know</span>
        <van-icon
          @click="showIcon = false"
          class="icon"
          size="15pt"
          name="cross"
        />
      </div>
      <div style="text-align: left; padding: 10pt">
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/1.png" />
          <span style="font-size: 13pt"
            >&nbsp;&nbsp;&nbsp;GF (Gluten Free)</span
          >
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/2.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;DF (Dairy Free)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/3.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;VG (Vegan)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/4.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;V (Vegetarian)</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10pt">
          <img class="icon" src="../assets/img/5.png" />
          <span style="font-size: 13pt">&nbsp;&nbsp;&nbsp;NF (Nuts Free)</span>
        </div>
      </div>
    </van-popup>
    <van-popup class="upsellModel" v-model="upsellShow" round>
      <div
        class="title word-break"
        style="font-weight: normal; text-align: left"
      >
        Would you like to add something extra?
      </div>
      <div class="content">
        <div
          class="item word-break"
          v-for="category in upsellCategorys"
          :key="category.id"
          style="display: flex; align-items: center"
          @click="showUpsell(category.id)"
        >
          <!-- <img
            src="@/assets/img/右箭头.png"
            style="height: 16pt; width: 16pt"
          /> -->
          <van-icon name="play-circle" size="15pt" style="margin-right: 5pt" />
          <span style="font-size: 13pt">
            {{ category.categoryName }}
          </span>
        </div>
      </div>
      <div class="btn-group">
        <van-button
          class="btn-skip"
          style="height: 30px"
          plain
          @click="skipUpsell"
          >SKIP</van-button
        >
      </div>
    </van-popup>
    <!-- 顾客扫码 -->
    <ScanCode
      ref="scan"
      v-if="visibleScan"
      @closed="visibleScan = false"
      @confirmFood="ScanCodeConfirmFood"
    />

    <!-- 员工扫码 -->
    <ScanCodeStaff
      ref="scanStaff"
      v-if="visibleScanStaff"
      @closed="visibleScanStaff = false"
      @successCallback="ScanCodeStaffCallback"
    />

    <van-uploader
      ref="vanUploader"
      style="display: none"
      :after-read="uploadImageFoodafterRead"
    />
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import { mapGetters, mapActions } from "vuex";
import { randomUUID } from "@/utils/utils";
import { Toast } from "vant";
import lodash from "lodash";
import request from "@/utils/request";
import { Dialog } from "vant";
import moment from "moment-timezone";
import ScanCode from "@/components/scanCode.vue";
import ScanCodeStaff from "@/components/scanCodeStaff.vue";
import MainItem from "@/components/mainItem/main-item.vue";
import MainStaffEnableUpc from "@/components/mainItem/main-staff-enableUpc.vue";

export default {
  name: "Home",
  components: { ScanCode, ScanCodeStaff, MainItem, MainStaffEnableUpc },
  data() {
    return {
      foodInfoShow: false,
      url: {
        list: "/adminApi/foodOrder/list",
        login: "/api/common/user/hotel_dept_name/login",
        getUserInfo: "/api/common/user/current_user",
        hotelQuery: "/api/sys_admin/hotel/query",
        getDisclaimerByHotelId: "/adminApi/foodOrder/getDisclaimerByHotelId",
        getOperationsByMealPeriodId:
          "/adminApi/foodOrder/getOperationsByMealPeriodId",
        getHotelInfo: "/adminApi/hotelinfo/infobyhotel",
        getPOSConfig: "/adminApi/omnivore/config",
      },
      mealPeriodList: [],
      activeName: "",
      selectFood: {}, //弹窗的餐品信息
      active: "",
      isSelectActive: true,
      activeMealPeriod: "",
      week: ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"],
      categoryList: [],
      hotelDeptName: "roomserviceqr",
      hotelName: "Hotel2",
      password: "654321",
      tips: "",
      // showDisclaims: false,
      timeOpen: false,
      showIcon: false,
      upsellShow: false,
      brandUrl: "",
      primaryColor: "",
      upsellCategorysId: [],
      disclaimerList: [],
      upsellCategorys: [],
      mealPeriodAdvancedArr: [],
      curMobilePrompt: "",
      addFoodBtn: true,
      endTimeTody: "",
      isAllowAdvanced: true,
      nearEndTipTime: "",
      posConfig: {},
      nowMealPeriodAdvanced: false,
      roomNum: "",
      locationType: "Guest Room",
      loading: true,
      visibleScan: false,
      visibleScanStaff: false,
      opt: "",
      enableUpc: false, // 是否是扫码商品
      scanCategoryList: [],
      uploadImageFood: {},
      showPrice: true,
    };
  },
  watch: {
    $route(to, from) {
      window.location.reload();
    },
  },
  computed: {
    ...mapGetters({
      shoppingFood: "foods", //购物车中的餐品
      allPrice: "allPrice",
      allNum: "allNum",
      order: "order",
    }),
  },
  created() {
    if (this.$route.query.room) {
      this.roomNum = this.$route.query.room;
    }
    if (this.$route.query.location) {
      this.locationType = this.$route.query.location;
    }
    if (this.$route.query.opt) {
      this.opt = this.$route.query.opt;
    }
    if (this.$route.query.user) {
      localStorage.setItem("staffUserName", this.$route.query.user);
    } else {
      localStorage.setItem("staffUserName", "");
    }
    var param = {
      hotel: "hotel2",
      name: "roomserviceqr",
      pwd: "654321",
    };
    let root = document.querySelector(":root");
    let primaryColor =
      getComputedStyle(root).getPropertyValue("--primary-color");
    this.primaryColor = primaryColor;
    // eyJob3RlbCI6ImhvdGVsMiIsIm5hbWUiOiJqYW5lZHUiLCJwd2QiOiI4ODg4ODgifQ==
    // if (this.$route.query.info) {
    //   let info = JSON.parse(this.$Base64.decode(this.$route.query.info))
    //   // let info = JSON.parse(this.$Base64.decode(this.$route.query.info))
    //   this.hotelName = info.hotel
    //   this.hotelDeptName = info.name
    //   this.password = info.pwd
    //   this.login()
    // }
    localStorage.setItem("locationType", this.locationType);
    if (this.$route.query.hotelname) {
      this.hotelName = this.$route.query.hotelname;
      this.login();
    } else {
      if (localStorage.getItem("userInfo")) {
        // this.getHotelInfo()
        this.getUserInfo();
        this.hotelQuery();
      }
    }
    if(this.hotelName == 'pmont' || this.hotelName == 'PMONT'){
      this.showPrice = false;
    }
    // if (this.$route.params.hotelName == 'hotel2') {
    //   this.hotelName = this.$route.params.hotelName
    //   this.hotelDeptName = 'janedu'
    //   this.password = '888888'
    //   this.login()
    // } else if (
    //   this.$route.params.hotelName == 'ATLHT' ||
    //   this.$route.params.hotelName == 'CIELO' ||
    //   this.$route.params.hotelName == 'STLEM'
    // ) {
    //   this.hotelName = this.$route.params.hotelName
    //   this.hotelDeptName = 'janedu'
    //   this.password = '123456'
    //   this.login()
    // }
  },
  methods: {
    onClickLeft() {},
    scanCode() {
      let mealPeriod = this.mealPeriodList.find(
        (item) => item.id == this.active
      );
      // let categoryList = mealPeriod.categoryList.map((item) => {
      //   return {
      //     text: item.categoryName,
      //     id: item.id,
      //     children: item.mainDishList.map((mainDishItem) => {
      //       return {
      //         text: mainDishItem.name,
      //         id: mainDishItem.id,
      //         upc: mainDishItem.upc,
      //         image: mainDishItem.image,
      //         fullPrice: mainDishItem.fullPrice,
      //         description: mainDishItem.description,
      //       };
      //     }),
      //   };
      // });
      this.visibleScan = true;
      this.$nextTick(() => {
        this.$refs.scan.init(mealPeriod.categoryList);
      });
    },
    showScanStaff(params) {
      console.log(
        "categoryId===============",
        params.categoryId,
        params.foodId
      );
      let mealPeriod = this.mealPeriodList.find(
        (item) => item.id == this.active
      );
      let categoryList = mealPeriod.categoryList.map((item) => {
        return {
          text: item.categoryName,
          id: item.id,
          children: item.mainDishList.map((mainDishItem) => {
            return {
              text: mainDishItem.name,
              id: mainDishItem.id,
              upc: mainDishItem.upc,
              image: mainDishItem.image,
            };
          }),
        };
      });
      params.categoryList = categoryList;
      this.visibleScanStaff = true;
      this.$nextTick(() => {
        this.$refs.scanStaff.init(params);
      });
    },
    ScanCodeStaffCallback({
      activeIndex,
      mainIndex,
      upcUpdateTime,
      upc,
      image,
    }) {
      console.log(
        "ScanCodeStaffCallback============",
        activeIndex,
        mainIndex,
        upcUpdateTime,
        upc,
        image
      );
      if (upcUpdateTime) {
        this.$set(
          this.categoryList[activeIndex].mainDishList[mainIndex],
          "upcUpdateTime",
          upcUpdateTime
        );
      }
      if (upc) {
        this.$set(
          this.categoryList[activeIndex].mainDishList[mainIndex],
          "upc",
          upc
        );
      }
      if (image) {
        this.$set(
          this.categoryList[activeIndex].mainDishList[mainIndex],
          "image",
          image
        );
      }
    },
    staffUploadFoodImage(foodInfo) {
      this.uploadImageFood = foodInfo;
      this.$refs.vanUploader.chooseFile();
    },
    uploadImageFoodafterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      request
        .post("/adminApi/file/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === "0") {
            request
              .post("/adminApi/foodOrder/saveMainDishUpc", {
                id: this.uploadImageFood.foodId,
                hotelId: localStorage.getItem("hotelId"),
                image: res.data,
              })
              .then((saveRes) => {
                if (saveRes.status === "0") {
                  // 图片回显
                  const { cateIndex, mainIndex } = this.uploadImageFood;
                  this.$set(
                    this.categoryList[cateIndex].mainDishList[mainIndex],
                    "image",
                    res.data
                  );
                  Toast("SUCCESS");
                } else {
                  Toast("Error" + saveRes.msg);
                }
              });
          } else {
            Toast("Error" + res.msg);
          }
        })
        .catch((err) => {
          Toast("Error" + JSON.stringify(err));
        });
    },
    activeNameChange(activeName) {
      if (activeName) {
        setTimeout(() => {
          const targetElement = document.getElementById(
            "collapse" + activeName
          );
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 400);
      }
    },
    moment,
    ...mapActions([
      "addFood",
      "removeFood",
      "updateMealPeriodOpenTime",
      "editColor",
    ]),
    login() {
      postAction(this.url.login, {
        hotelDeptName: this.hotelDeptName,
        hotelName: this.hotelName,
        password: this.password,
      })
        .then((res) => {
          if (res.msg == "Login success") {
            localStorage.setItem("hotelName", this.hotelName);
            localStorage.setItem("HTTOKEN", res.data.token);
            localStorage.setItem(
              "loginInfo",
              JSON.stringify({
                hotelDeptName: this.hotelDeptName,
                hotelName: this.hotelName,
                password: this.password,
              })
            );
            this.getUserInfo();
            this.hotelQuery();
          } else {
            Toast({
              message: `Invalid RoomServiceQR access`,
              duration: 3000,
              className: "toast",
              getContainer: () => {
                return this.$refs.home;
              },
            });
          }
        })
        .catch((err) => {
          Toast({
            message: `Invalid RoomServiceQR access`,
            duration: 3000,
            className: "toast",
            getContainer: () => {
              return this.$refs.home;
            },
          });
        });
    },
    getDisclaimer() {
      this.disclaimer = "";
      getAction(this.url.getDisclaimerByHotelId, {
        hotelId: localStorage.getItem("hotelId"),
      }).then((res) => {
        this.disclaimer = res.data.disclaimer;
      });
    },
    getUserInfo() {
      getAction(this.url.getUserInfo).then((res) => {
        localStorage.setItem("hotelId", res.data.hotelId);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("hotelDeptId", res.data.hotelDeptId);
        localStorage.setItem("hotelDeptName", res.data.hotelDeptName);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.initData();
        // this.getDisclaimer()
        this.getHotelInfo();
        this.getPosConfig();
      });
    },
    getPosConfig() {
      getAction(
        `${this.url.getPOSConfig}?hotelId=${localStorage.getItem("hotelId")}`
      ).then((res) => {
        localStorage.setItem("posConfig", JSON.stringify(res.data));
        this.posConfig = JSON.parse(localStorage.getItem("posConfig")) || {
          posBridge: "HVT",
        };
      });
    },
    hotelQuery() {
      postAction(this.url.hotelQuery, {
        hotelNo: localStorage.getItem("hotelName"),
      }).then((res) => {
        this.hotelName = res.data.name;
        localStorage.setItem("timeZone", res.data.timeZone);
        localStorage.setItem("activeTime", res.data.activeTime);
      });
    },
    seeToast(value) {
      Toast(value);
    },
    goMyOrder() {
      if (this.order.length == 0) {
        return;
      }
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/myOrder" });
      } else {
        this.$router.push({
          path: "/myOrder",
          query: query,
        });
      }
    },
    async chooseType(mealPeriod, needShowId) {
      // let container = this.$refs.section;
      // let scrollLeft = 0;
      // let index = 0;
      // let width = 0;
      // for (let i = 0; i < container.children.length; i++) {
      //   const element = container.children[i];
      //   if (element.id == mealPeriod.id) {
      //     if (i != 0) {
      //       index = i;
      //       width += container.children[i].clientWidth / 2;
      //       console.log(container.children[i].clientWidth / 2);
      //     } else {
      //       width = 0;
      //     }
      //     break;
      //   } else {
      //     width += container.children[i].clientWidth + 20;
      //   }
      // }
      // scrollLeft = width - container.clientWidth / 2;
      // container.scrollTo({ left: scrollLeft, behavior: "smooth" });
      var that = this;
      that.timeTips = "";
      that.tips = "";
      that.curMobilePrompt = mealPeriod.mobilePrompt || "";
      let now = moment(new Date()).tz(localStorage.getItem("timeZone"));

      let param = {
        hotelId: localStorage.getItem("hotelId"),
        mealPeriodId: mealPeriod.id,
      };
      this.isAllowAdvanced = mealPeriod.isAllow;
      localStorage.setItem("autoClosePaid", mealPeriod.autoClosePaid);
      getAction(that.url.getOperationsByMealPeriodId, param).then((res) => {
        if (res.msg == "SUCCESS") {
          let mealPeriodOpenTimeArr = [];
          //兼容safari浏览器
          res.data.map((item) => {
            let obj = {
              date: item.date.replace(/-/g, "/"),
              fromTime: item.fromTime,
              toTime: item.toTime,
              is24Available: item.is24Available,
            };
            mealPeriodOpenTimeArr.push(obj);
          });

          that.nowMealPeriodAdvanced = mealPeriod.isAllow;
          that.getTips(mealPeriodOpenTimeArr, mealPeriod.isAllow);

          //将开放时间和提示放到vuex
          let mealPeriodOpenTime = {
            mealPeriod: mealPeriod.mealPeriod,
            mealPeriodId: mealPeriod.id,
            mealPeriodOpenTimeArr: mealPeriodOpenTimeArr,
            tips: that.tips,
            isAllowAdvanced: mealPeriod.isAllow,
            advancedConfig: mealPeriod.advancedConfig,
            notAdvancedConfigList: mealPeriod.notAdvancedConfigList,
            enableText: mealPeriod.enableText,
            discount: mealPeriod.discount,
          };
          that.updateMealPeriodOpenTime(mealPeriodOpenTime);

          if (mealPeriod.id != that.active) {
            that.active = mealPeriod.id;
            this.enableUpc = mealPeriod.enableUpc;
            that.activeMealPeriod = mealPeriod.mealPeriod;
            for (let i = 0; i < that.mealPeriodList.length; i++) {
              const element = that.mealPeriodList[i];
              if (element.id == mealPeriod.id) {
                that.categoryList = element.categoryList;
                if (that.categoryList.length > 0) {
                  if (needShowId != null) {
                    that.activeName = needShowId;
                  } else {
                    that.activeName = that.categoryList[0].id;
                  }
                }
              }
            }
          } else {
            if (needShowId != null) {
              that.activeName = needShowId;
            }
          }
          if (needShowId != null) {
            setTimeout(() => {
              const targetElement = document.getElementById(
                "collapse" + that.activeName
              );
              targetElement.scrollIntoView({ behavior: "smooth" });
            }, 400);
            // that.$nextTick(() => {
            //   setTimeout(() => {
            //     that.$refs.categoryContent.scrollTo({
            //       top:
            //         document.getElementById(needShowId).offsetTop +
            //         that.$refs.tips.clientHeight,
            //       behavior: "smooth",
            //     });
            //   }, 400);
            // });
          } else {
            that.$nextTick(() => {
              setTimeout(() => {
                that.$refs.categoryContent.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }, 100);
            });
          }
          this.loading = false;
        }
      });
    },

    getTips(mealPeriodOpenTimeArr, isAllowAdvanced) {
      this.nearEndTipTime = "";
      let now = moment(new Date()).tz(localStorage.getItem("timeZone"));
      let date = now.format("YYYY/MM/DD");
      let tomorrow = moment(now.valueOf() + 1000 * 60 * 60 * 24)
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY/MM/DD");
      // let dateOf15 = moment(now.valueOf() + 1000 * 60 * 60 * 24 * 15)
      //   .tz(localStorage.getItem('timeZone'))
      //   .format('MM/DD/YYYY') //15天后的日期

      for (let i = 0; i < mealPeriodOpenTimeArr.length; i++) {
        const condition = mealPeriodOpenTimeArr[i];
        if (condition.is24Available) {
          this.timeOpen = true;
          this.tips = "Available.";
          break;
        }
        let startTime = moment
          .tz(
            moment(condition.date + " " + condition.fromTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            localStorage.getItem("timeZone")
          )
          .valueOf();
        let endTime = moment
          .tz(
            moment(condition.date + " " + condition.toTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            localStorage.getItem("timeZone")
          )
          .valueOf();
        //今天

        if (date == condition.date) {
          if (now.valueOf() < startTime) {
            this.timeOpen = false;
            this.tips =
              "Not available until today at " + condition.fromTime + ".";
            break;
          } else if (startTime <= now.valueOf() && now.valueOf() <= endTime) {
            this.timeOpen = true;
            this.tips = "Available until " + condition.toTime + " today.";
            //
            // //如果离结束还剩不到30分钟，显示提示
            // if (now.valueOf() + 1000 * 60 * 30 >= endTime) {
            //   this.nearEndTipTime = condition.toTime
            // }
            break;
          } else if (endTime < now.valueOf()) {
            if (i < mealPeriodOpenTimeArr.length - 1) {
              let nextStartTime = moment
                .tz(
                  moment(
                    mealPeriodOpenTimeArr[i + 1].date +
                      " " +
                      mealPeriodOpenTimeArr[i + 1].fromTime
                  ).format("YYYY-MM-DD HH:mm"),
                  localStorage.getItem("timeZone")
                )
                .valueOf();
              if (now.valueOf() < nextStartTime) {
                if (mealPeriodOpenTimeArr[i + 1].date == date) {
                  this.timeOpen = false;
                  this.tips =
                    "Not available until today at " +
                    mealPeriodOpenTimeArr[i + 1].fromTime +
                    ".";
                  break;
                } else if (mealPeriodOpenTimeArr[i + 1].date == tomorrow) {
                  this.timeOpen = false;
                  this.tips =
                    "Not available until tomorrow at " +
                    mealPeriodOpenTimeArr[i + 1].fromTime +
                    ".";
                  break;
                } else {
                  this.timeOpen = false;
                  this.tips =
                    "Not available until " +
                    mealPeriodOpenTimeArr[i + 1].date +
                    " at " +
                    mealPeriodOpenTimeArr[i + 1].fromTime +
                    ".";
                  break;
                }
              }
            }
          }
        }
        //明天
        else if (tomorrow == condition.date) {
          this.timeOpen = false;
          this.tips =
            "Not available until tomorrow at " + condition.fromTime + ".";
          break;
        } else {
          this.timeOpen = false;
          this.tips =
            "Not available until " +
            moment(condition.date).format("MM/DD/YYYY") +
            " at " +
            condition.fromTime +
            ".";
          break;
        }
      }
      if (!this.tips) {
        this.timeOpen = false;
        this.tips = "Not available in the next two weeks.";
      }
      // 添加按钮是否禁用
      this.addFoodBtn = this.timeOpen || isAllowAdvanced;
      //如果离结束还剩不到10分钟，禁止点餐
      if (mealPeriodOpenTimeArr.length == 0) {
        this.addFoodBtn = false;
        return;
      }
      //24小时供餐，不需要10分钟逻辑
      if (mealPeriodOpenTimeArr[0].is24Available) {
        return;
      }
      this.endTimeTody = moment
        .tz(
          moment(
            mealPeriodOpenTimeArr[0].date +
              " " +
              mealPeriodOpenTimeArr[0].toTime
          ).format("YYYY-MM-DD HH:mm"),
          localStorage.getItem("timeZone")
        )
        .valueOf();

      if (now.valueOf() + 1000 * 60 * 10 >= this.endTimeTody) {
        // this.addFoodBtn = false;
        // this.
        this.nearEndTipTime = mealPeriodOpenTimeArr[0].toTime;
        this.nearEndTipTime = moment(this.endTimeTody - 1000 * 60 * 10)
          .tz(localStorage.getItem("timeZone"))
          .format("hh:mm A");
        // 如果不允许点餐，打烊前十分钟不允许点餐
        if (!isAllowAdvanced) {
          this.addFoodBtn = false;
        }
      } else if (now.valueOf() + 1000 * 60 * 15 >= this.endTimeTody) {
        this.nearEndTipTime = mealPeriodOpenTimeArr[0].toTime;
        this.nearEndTipTime = moment(this.endTimeTody - 1000 * 60 * 10)
          .tz(localStorage.getItem("timeZone"))
          .format("hh:mm A");
        this.addFoodBtn = true;
      }
    },
    initData() {
      // this.showDisclaims = false
      this.mealPeriodList = [];
      var param = {
        hotelId: localStorage.getItem("hotelId"),
      };

      param.locationType = this.locationType;

      getAction(this.url.list, param).then(async (res) => {
        if (res.msg == "SUCCESS") {
          // this.showDisclaims = true
          this.mealPeriodList = res.data;
          //数量初始化
          for (let i = 0; i < this.mealPeriodList.length; i++) {
            const mealPeriod = this.mealPeriodList[i];
            let mealPeriodAdvanced = {
              advancedConfig: mealPeriod.advancedConfig,
              isAllow: mealPeriod.isAllow,
              id: mealPeriod.id,
            };
            this.mealPeriodAdvancedArr.push(mealPeriodAdvanced);
            for (let j = 0; j < mealPeriod.categoryList.length; j++) {
              const category = mealPeriod.categoryList[j];
              if (category.upsell === true) {
                this.upsellCategorysId.push(category.id);
                this.upsellCategorys.push(category);
              }
              for (let k = 0; k < category.mainDishList.length; k++) {
                const food = category.mainDishList[k];
                food.num = 0;
                for (let l = 0; l < this.shoppingFood.length; l++) {
                  const shoppFood = this.shoppingFood[l];
                  if (food.id == shoppFood.foodId) {
                    food.num += shoppFood.num;
                  }
                }
                //数据初始化
                food.foods = [];
                food.drinks = [];
                food.drinksChildren = [];
                food.drinksChildrenChecked = "";
                food.remark = "";
                // food.isFull = true;
                //区分饮品和其他
                for (let i = 0; i < food.sideDishList.length; i++) {
                  const element = food.sideDishList[i];
                  if (element.isBeverage) {
                    food.drinks.push(element);
                  } else {
                    food.foods.push(element);
                  }
                }
                //=============赋予默认值start
                for (let i = 0; i < food.foods.length; i++) {
                  const element = food.foods[i];
                  if (element.optionList.length > 0) {
                    if (element.type == "Radio") {
                      //   element.checked = ''
                      element.checked = [element.optionList[0].id];
                    } else {
                      element.checked = [];
                      // element.checked = [element.optionList[0].id];
                    }
                  }
                }
                //=============赋予默认值end
                //饮品处理
                for (let i = 0; i < food.drinks.length; i++) {
                  const element = food.drinks[i];
                  if (i == 0) {
                    element.checked = true;
                  } else {
                    element.checked = false;
                  }
                }
                if (food.drinks.length > 0) {
                  food.drinksChildren = food.drinks[0].optionList;
                  //=============赋予默认值start
                  if (food.drinksChildren.length > 0) {
                    // food.drinksChildrenChecked = ''
                    food.drinksChildrenChecked = food.drinksChildren[0].id;
                  }
                  //=============赋予默认值end
                }
              }
            }
          }
          // if (this.mealPeriodList.length > 0) {
          // this.active = this.mealPeriodList[0].id
          // this.categoryList = this.mealPeriodList[0].categoryList
          // if (this.categoryList.length > 0) {
          //   this.activeName = [this.categoryList[0].id]
          // }
          // this.chooseType(this.mealPeriodList[0]);
          // }
          localStorage.setItem(
            "mealPeriodAdvanced",
            JSON.stringify(this.mealPeriodAdvancedArr)
          );

          // 根据时间选择当前Meal
          // 根据时间选择当前Meal
          for (let mealPeriod of this.mealPeriodList) {
            if (!this.isSelectActive) {
              return;
            }
            let isActive = await getAction(
              this.url.getOperationsByMealPeriodId,
              {
                hotelId: localStorage.getItem("hotelId"),
                mealPeriodId: mealPeriod.id,
              }
            ).then((res) => {
              if (res.msg === "SUCCESS") {
                if (res.data.length > 0) {
                  let now = moment(new Date())
                    .tz(localStorage.getItem("timeZone"))
                    .valueOf();
                  let firstDay = res.data[0];
                  let fromTime = moment
                    .tz(
                      moment(
                        firstDay.date.replace(/-/g, "/") +
                          " " +
                          firstDay.fromTime
                      ).format("YYYY-MM-DD HH:mm"),
                      localStorage.getItem("timeZone")
                    )
                    .valueOf();
                  let stopTime = moment
                    .tz(
                      moment(
                        firstDay.date.replace(/-/g, "/") + " " + firstDay.toTime
                      ).format("YYYY-MM-DD HH:mm"),
                      localStorage.getItem("timeZone")
                    )
                    .valueOf();
                  if (now >= fromTime && now <= stopTime) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else {
                return false;
              }
            });
            // console.log(mealPeriod, isActive);
            if (isActive) {
              this.chooseType(mealPeriod);
              this.isSelectActive = false;
              localStorage.setItem("autoClosePaid", mealPeriod.autoClosePaid);
            }
          }
          if (this.isSelectActive === true) {
            if (this.mealPeriodList.length > 0) {
              this.chooseType(this.mealPeriodList[0]);
              localStorage.setItem("autoClosePaid", this.mealPeriodList[0].autoClosePaid);
            } else {
              this.loading = false;
            }
          }
          // this.loading = false;
        }
      });
    },
    choseDrink(val) {
      var target = null;
      for (let i = 0; i < this.selectFood.drinks.length; i++) {
        const item = this.selectFood.drinks[i];
        item.checked = false;
        if (item.id == val) {
          target = item;
        }
      }
      if (target) {
        target.checked = true;
        this.selectFood.drinksChildren = target.optionList;
        // if (this.selectFood.drinksChildren.length > 0) {
        // this.selectFood.drinksChildrenChecked = ''
        // this.selectFood.drinksChildrenChecked =
        //   this.selectFood.drinksChildren[0].id
        // }
      }
    },
    showFoodInfo(food) {
      if (food.oosStatus) {
        return;
      }
      // 不允许点餐
      this.selectFood = lodash.cloneDeep(food);
      this.selectFood.num = 1;
      // console.log(this.selectFood);
      this.foodInfoShow = true;
    },
    ScanCodeConfirmFood(food) {
      this.selectFood = lodash.cloneDeep(food);
      this.selectFood.num = food.num;
      this.activeName = food.categoryId;
      setTimeout(() => {
        const targetElement = document.getElementById(
          "collapseMainItem" + food.id
        );
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 400);
      this.addShoppingFood();
    },
    radioChange(type, index) {
      if (type == "radio") {
        if (this.selectFood.foods[index].checked.length > 1) {
          this.selectFood.foods[index].checked.shift();
        }
      } else if (type == "beverage") {
        if (this.selectFood.drinksChildrenChecked.length > 1) {
          this.selectFood.drinksChildrenChecked.shift();
        }
      }
    },
    addShoppingFood() {
      if (this.shoppingFood.length > 0) {
        if (this.active != this.shoppingFood[0].mealPeriodId) {
          let target = this.mealPeriodList.find((item) => {
            return item.id == this.shoppingFood[0].mealPeriodId;
          });
          Toast(
            "Please finish or remove the " +
              target.mealPeriod +
              " order firstly."
          );
          return;
        }
      }

      // 添加disclaimer
      let disclaimerList = [];
      let category = this.categoryList.find(
        (item) => item.id === this.activeName
      );

      category.disclaimerList.forEach((item) => {
        if (item.enable) {
          disclaimerList.push(item);
        }
      });
      console.log(category);

      if (this.selectFood.num > 0) {
        var food = {
          id: randomUUID(), //订单餐品id
          foodId: this.selectFood.id, //餐品id
          drinks: this.selectFood.drinks, //所有饮品类别及其下饮品
          drinksChildren: this.selectFood.drinksChildren, //饮品类别下的饮品
          drinksChildrenChecked: this.selectFood.drinksChildrenChecked, //饮品类别下的选择的饮品
          foods: this.selectFood.foods, //所有食物
          remark: this.selectFood.remark,
          hotelId: this.selectFood.hotelId,
          name: this.selectFood.name,
          // isFull: this.selectFood.isFull,
          fullPrice: this.selectFood.fullPrice,
          // halfPrice: this.selectFood.halfPrice,
          // fullName: this.selectFood.fullName,
          // halfName: this.selectFood.halfName,
          sideDishList: this.selectFood.sideDishList,
          allPrice: 0,
          num: this.selectFood.num,
          // mealPeriodId: this.selectFood.mealPeriodId,
          mealPeriodId: this.active,
          // categoryId: this.selectFood.categoryId,
          categoryId: this.activeName,
          disclaimer: disclaimerList || null,
          omnId: this.selectFood.omnId,
          messageModifierId: this.selectFood.messageModifierId,
          accountingType: this.selectFood.accountingType
            ? this.selectFood.accountingType
            : category.accountingType,
        };
        //计算价格
        for (let i = 0; i < food.drinksChildren.length; i++) {
          const drink = food.drinksChildren[i];
          if (drink.id == food.drinksChildrenChecked) {
            food.allPrice += Number(drink.price);
          }
        }
        for (let i = 0; i < food.foods.length; i++) {
          const element = food.foods[i];
          for (let j = 0; j < element.optionList.length; j++) {
            const item = element.optionList[j];
            if (element.checked.indexOf(item.id) != -1) {
              food.allPrice += Number(item.price);
            }
            // if (element.type == "Radio") {
            //   if (item.id == element.checked) {
            //     food.allPrice += Number(item.price);
            //   }
            // } else {
            //   if (element.checked.indexOf(item.id) != -1) {
            //     food.allPrice += Number(item.price);
            //   }
            // }
          }
        }
        food.allPrice += Number(food.fullPrice);
        food.allPrice = food.allPrice * food.num;
        this.addFood(food);
        // this.selectFood.num++
        for (let i = 0; i < this.categoryList.length; i++) {
          const category = this.categoryList[i];
          for (let j = 0; j < category.mainDishList.length; j++) {
            const food = category.mainDishList[j];
            if (food.id == this.selectFood.id) {
              this.$set(this.categoryList[i].mainDishList, j, {
                ...food,
                num: food.num + this.selectFood.num,
              });
            }
          }
        }
        this.foodInfoShow = false;
      } else {
        this.foodInfoShow = false;
      }
    },
    close() {
      this.foodInfoShow = false;
    },
    placeOrder() {
      if (this.shoppingFood.length == 0) {
        return;
      }
      let selectCategoryIds = Array.from(
        new Set(this.shoppingFood.map((item) => item.categoryId))
      );
      let isUpsell = selectCategoryIds.some((id) => {
        return this.upsellCategorysId.find((item) => id === item);
      });
      this.upsellCategorys = this.upsellCategorys.filter((item) => {
        // return this.shoppingFood.findIndex(food => item.mealPeriodId == food.mealPeriodId) != -1 && selectCategoryIds.findIndex(id => item.category == id) != 1
        return (
          selectCategoryIds.findIndex((id) => item.id == id) === -1 &&
          this.shoppingFood.findIndex(
            (food) => item.mealPeriodId == food.mealPeriodId
          ) != -1
        );
      });
      if (this.upsellCategorys.length != 0) {
        // 筛选upsell categorys
        this.upsellShow = true;
      } else {
        let query = {};
        if (this.roomNum) {
          query.room = this.roomNum;
        }
        if (this.locationType) {
          query.location = this.locationType;
        }
        if (!this.roomNum && !this.locationType) {
          this.$router.push({ path: "/placeOrder" });
        } else {
          this.$router.push({
            path: "/placeOrder",
            query: query,
          });
        }
      }
    },
    skipUpsell() {
      this.upsellShow = false;
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/placeOrder" });
      } else {
        this.$router.push({
          path: "/placeOrder",
          query: query,
        });
      }
    },
    okUpsell() {
      this.upsellShow = false;
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/placeOrder" });
      } else {
        this.$router.push({
          path: "/placeOrder",
          query: query,
        });
      }
    },
    checkbox(item, id) {
      if (item.type == "Radio") {
        if (item.checked.length == 0) {
          Toast("Choose at least 1");
          item.checked.push(id);
        } else if (item.maxNum == 1) {
          item.checked = [id];
        }
      }
    },
    checkedChange(item) {
      if (item.type == "Radio" && item.maxNum == 1) {
        return;
      }
      if (item.checked.length > item.maxNum) {
        Toast("Choose up to " + item.maxNum);
        item.checked.pop();
        return;
      }
    },
    showInfo() {
      Dialog.alert({
        message: this.disclaimer,
        confirmButtonText: "Confirm",
        theme: "round-button",
        confirmButtonColor: this.primaryColor,
      }).then(() => {
        // on close
      });
    },
    showIconInfo() {
      this.showIcon = true;
    },

    getHotelInfo() {
      getAction(this.url.getHotelInfo, {
        hotelId: localStorage.getItem("hotelId"),
      }).then((res) => {
        if (this.$route.query.use_sign_color) {
          this.editColor("#" + this.$route.query.use_sign_color);
        } else {
          this.editColor("#" + res.data.color);
        }
        let root = document.querySelector(":root");
        let primaryColor =
          getComputedStyle(root).getPropertyValue("--primary-color");
        this.primaryColor = primaryColor;

        this.brandUrl =
          // "https://www.hotelvoicebot.com/download/resource/logo/" +
          "https://hotelfile353485474178.s3-accelerate.amazonaws.com/logo/" +
          res.data.brand.toLowerCase().replace(" ", "_") +
          ".png";
      });
    },
    showUpsell(id) {
      let mealPeriod = this.mealPeriodList.find((item) => {
        return (
          item.id == this.active &&
          item.categoryList.findIndex((category) => category.id === id) != -1
        );
      });
      this.chooseType(mealPeriod, id);
      this.upsellShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.van-nav-bar__right {
  position: static !important;
}
/deep/.van-nav-bar__right:active {
  opacity: 1;
}
/deep/.navbar-scan {
  fill: #fff !important;
  font-size: 20pt;
}
.home {
  width: 100%;

  .toast {
    word-break: normal;
  }

  .word-break {
    word-break: normal;
  }

  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
      margin: 0 0 0 5pt;
      max-width: 85%;
    }
  }

  /deep/ .van-grid-item__content {
    background: #f5f4f4;
    // padding: 0.42667rem 0.11333rem;
    // padding: 0.42667rem 3.75pt;
    padding: 17pt 3.75pt 7.5pt;
  }

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .section-area {
    position: relative;
    // width: calc(100% - 20pt);
    // margin: 5pt 10pt;
    width: calc(100% - 40pt);
    margin: 1pt 20pt;

    .section {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      overflow-x: auto;
      width: 100%;
      height: 40pt;

      .item {
        flex-shrink: 0;
        // width: 31%;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-right: 20px;

        .top-text {
          align-self: center;
          margin-top: 5pt;
          font-size: 10pt;
          font-weight: bold;
          padding: 4pt;
          border-radius: 3pt;
          width: fit-content;
          white-space: nowrap;
        }

        .activeType {
          align-self: center;
          color: #fff;
          background: $primary-color !important;
        }
      }

      .item:last-child {
        margin: 0;
      }
    }

    // .section::-webkit-scrollbar {
    //   display: none;
    // }

    .arrow-left {
      position: absolute;
      left: -6%;
      top: 33%;
    }

    .arrow-right {
      position: absolute;
      right: -6%;
      top: 33%;
    }
  }

  //.content::-webkit-scrollbar {
  //  display: none;
  //}
  .content {
    height: calc(100% - 145pt);
    width: calc(100% - 12pt);
    padding: 0 10pt;
    text-align: left;
    overflow: auto;

    .van-collapse-item--border::after {
      border-top: none;
    }

    .van-collapse-item {
      margin-bottom: 10pt;
    }

    .food-collapse {
      .item {
        padding: 10pt 0 2pt;
        border-bottom: 1pt dashed #e8e8e8;
      }
    }

    /deep/ .van-cell {
      padding: 10pt 16pt;

      &::after {
        bottom: 2px;
        border-bottom: 4px solid rgb(223, 223, 223);
      }
    }

    /deep/ .van-cell__title,
    .van-cell__value {
      font-size: 13pt;
      font-family: ArialRoundedMTBold;
      color: #2b2e36;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: normal;
    }

    /deep/ .van-collapse-item__content {
      padding-top: 0;
      padding-bottom: 0;
    }

    // .item {
    //   position: relative;
    //   //border-bottom: 1px dashed #dbdada;
    //   padding: 10pt 0;

    //   .name {
    //     font-size: 11pt;
    //     font-family: PingFangSC-Semibold, PingFang SC;
    //     // font-weight: 600;
    //     color: #2b2e36;
    //     display: flex;
    //     align-items: center;
    //     // width: 180pt;
    //     // width: auto;
    //     width: 80%;

    //     .icon {
    //       width: 15pt;
    //       height: 15pt;
    //       background-repeat: no-repeat;
    //       margin-right: 5.5pt;
    //     }

    //     .tip {
    //       position: absolute;
    //       top: -30%;
    //       right: 0%;
    //       width: 12pt;
    //     }
    //   }

    //   .price {
    //     font-size: 10pt;
    //     font-family: ArialNarrow;
    //     color: #2b2e36;
    //     padding-bottom: 2pt;
    //   }

    //   .info {
    //     font-size: 10pt;
    //     font-family: PingFangSC-Regular, PingFang SC;
    //     font-weight: 400;
    //     color: #636464;
    //   }

    //   .add {
    //     position: absolute;
    //     right: 0;
    //     top: 10pt;
    //     // top: 36%;
    //     color: $primary-color;

    //     img {
    //       width: 15pt;
    //       height: 15pt;
    //       background-repeat: no-repeat;
    //     }

    //     /deep/.van-icon {
    //       color: $primary-color;
    //     }

    //     /deep/ .van-badge--fixed {
    //       //top: 10pt;
    //     }
    //   }

    //   .scan-small {
    //     position: absolute;
    //     right: 0;
    //     top: 10pt;
    //     // top: 36%;
    //     color: $primary-color;
    //     display: flex;
    //     align-items: center;

    //     /deep/.van-icon {
    //       color: $primary-color;
    //     }
    //   }
    // }

    // .no-border {
    //   border: 0pt !important;
    // }

    // .oos {
    //   background-color: #e8e8e8;
    // }
  }

  .bottom {
    height: 30pt;
    width: calc(100% - 20pt);
    background: #ffffff;
    border-radius: 25pt;
    margin: 5pt 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /deep/ .van-badge--fixed {
      top: 10pt;
    }

    img {
      width: 20pt;
      height: 20pt;
      background-repeat: no-repeat;
      margin-left: 20pt;
    }

    .price {
      font-size: 13pt;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #2e2e2e;
    }

    .btn {
      width: 120pt;
      height: 30pt;
      background: #2b2e36;
      background: $primary-color;
      border-radius: 25pt;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 13pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5pt 10pt;

    .footer-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30pt;
      background-color: $primary-color;
      border-radius: 25pt;
      width: 120pt;

      /deep/ .van-badge--fixed {
        top: 10pt;
      }

      /deep/.footer-scan {
        fill: #fff;
        width: 18pt;
      }

      span {
        font-size: 11pt;
        font-weight: 600;
        color: #ffffff;
        font-family: PingFangSC-Semibold, PingFang SC;
        margin-left: 4px;
      }
    }
    .footer-item:nth-child(2) {
      margin-left: 10pt;
    }
  }

  .footer-staff {
    height: 30pt;
    width: calc(100% - 20pt);
    background: $primary-color;
    border-radius: 5pt;
    margin: 5pt 0;

    font-size: 14pt;
    font-weight: 600;
    color: #ffffff;
    font-family: PingFangSC-Semibold, PingFang SC;
    text-align: center;
    line-height: 30pt;
  }

  .foodModel {
    text-align: left;
    background: #f9f9f9;

    .title {
      background-color: #ffffff;
      padding: 0 10pt;
      border-bottom: 1pt #dcdcdc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60pt;

      span {
        font-size: 14pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2b2e36;
      }
    }

    .item {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;
      margin-bottom: 2pt;

      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .checked-group {
        /deep/ .van-checkbox__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/ .van-checkbox__icon--checked .van-icon {
          //background-color: #686868;
          //border-color: #686868;
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      .radio-group {
        /deep/ .van-radio__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/ .van-radio__icon--checked .van-icon {
          //background-color: #686868;
          //border-color: #686868;
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      .select {
        display: flex;
        align-items: center;
        margin-bottom: 5pt;

        .type {
          width: 60pt;
          height: 20pt;
          background: #ffffff;
          border-radius: 3.5pt;
          border: 1pt solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;

          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b2e36;
          }
        }

        .type-checked {
          // background: #f7f7f7;
          //background: rgba(0, 0, 0, 0.7);
          background-color: $primary-color;
          border: 1pt solid $primary-color;
          width: 60pt;
          height: 20pt;
          border-radius: 3.5pt;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;

          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: #2b2e36;
            color: #ffffff;
          }
        }
      }
    }

    .remark {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;

      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .input {
        margin-top: 5pt;
        margin-bottom: 10pt;
        border-radius: 1pt;
        border: 1pt solid #979797;
      }
    }

    .btn {
      height: 30pt;
      border-radius: 5pt;
      background-color: $primary-color;

      /deep/ .van-button__text {
        font-size: 12pt;
      }
    }
  }

  .iconModel {
    text-align: left;
    background: #ffffff;
    width: 80%;

    .title {
      background-color: #ffffff;
      padding: 0 10pt;
      border-bottom: 1pt #dcdcdc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30pt;

      span {
        font-size: 14pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2b2e36;
      }
    }
  }
}

/deep/ .van-toast {
  font-size: 15px;
  word-break: normal;
  text-align: left !important;
}

/deep/ .van-cell__label {
  font-weight: normal;
}

.upsellModel {
  padding: 10pt;
  width: 260pt;
  background: #f9f9f9;
  font-size: 12pt;

  .title {
    font-weight: 600;
    font-size: 12pt;
    margin-bottom: 10pt;
  }

  /deep/.van-icon {
    color: $primary-color;
  }

  .content {
    .item {
      line-height: 16pt;
      padding: 4pt 0;
      font-size: 11pt;

      span {
        text-decoration: underline;
      }
    }
  }

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    margin-top: 26pt;

    .btn-skip {
      border-color: $primary-color;
      color: $primary-color;
      width: 100%;
    }
  }
}

.hotelname {
  color: #fff;
  font-size: 10pt;
  margin-left: 10px;
  text-align: left;
  //width: 200pt;
  width: calc(100vw - 110pt);
  word-break: normal;
  line-height: 11pt;
  font-weight: normal;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.orderNum {
  color: #fff;
  border: 1px solid #fff;
  padding: 0 3px;
  background-color: #ee0a24;
  min-width: 16px;
  position: absolute;
  right: 10pt;
  top: 5pt;
  font-size: 12px;
  line-height: 1.2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
