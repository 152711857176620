var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",class:[_vm.index == _vm.item.mainDishList.length - 1 && 'no-border']},[(_vm.food.image)?_c('div',{staticClass:"imageBox",on:{"click":function($event){$event.stopPropagation();return _vm.preview(_vm.food.image)}}},[_c('img',{attrs:{"src":_vm.food.image,"alt":""}})]):_vm._e(),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"word-break"},[_vm._v(" "+_vm._s(_vm.food.name)+" ")]),_c('div',{staticClass:"operation"},[_c('svg-icon',{attrs:{"iconClass":"zxj","className":"zxj"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('uploadImage', {
              foodId: _vm.food.id,
              cateIndex: _vm.cateIndex,
              mainIndex: _vm.index,
            })}}}),_c('svg-icon',{attrs:{"iconClass":"scan","className":"scan"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showScanStaff', {
              foodId: _vm.food.id,
              foodName: _vm.food.name,
              foodImage: _vm.food.image,
              categoryId: _vm.item.id,
            })}}})],1)]),_c('div',{staticClass:"upcCode"},[_c('div',{staticClass:"price"},[_vm._v("$"+_vm._s(_vm.food.fullPrice))]),_c('div',{staticClass:"right"},[(_vm.food.upc)?_c('div',[_c('div',{staticClass:"omit"},[_vm._v("  UPC:   "+_vm._s(_vm.food.upc || "null")+" ")]),_c('div',[_vm._v(" Time:   "+_vm._s(_vm.food.upcUpdateTime ? _vm.dealWithDate(_vm.food.upcUpdateTime) : "null")+" ")])]):_vm._e(),_c('div',{staticClass:"edit",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showScanStaff', {
              foodId: _vm.food.id,
              foodName: _vm.food.name,
              foodImage: _vm.food.image,
              upc: _vm.food.upc,
              categoryId: _vm.item.id,
              type: 'edit',
            })}}},[_vm._v(" Edit ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }